import * as React from 'react';
import { styled } from '@mui/system';
import { alpha } from '@mui/material';

import { StaticImage } from 'gatsby-plugin-image';

// @material-ui/core components
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import Stack from '@mui/material/Stack';
import { Grid } from '@mui/material';
import { Button } from '@mui/material';

// import separator from '../images/separatorWhite.png';

const Section = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	backgroundColor: alpha(theme.palette.background.paper, 0),
	position: 'relative',
	scrollSnapType: 'y mandatory',
	scrollBehavior: 'smooth',
}));

const FrontContent = styled(Box)({
	marginTop: 'auto',
	marginBottom: '50px',
	margin: 'auto',
	// color: '#000',
});

const ScrollAnchor = styled(Box)({
	position: 'absolute',
	top: '-84px',
	scrollSnapAlign: 'start',
	scrollSnapStop: 'always',
});

function AboutSection() {
	return (
		<Section className='section' px={8}>
			<ScrollAnchor id='mantra' />
			<FrontContent px={8} maxWidth='lg' className='pItem pFnt'>
				<Box className='container' maxWidth='lg' py={4}>
					<Grid container justify='center'>
						<Grid item xs={12} sm={8}>
							<Typography my={2} component='h5' variant='h5' color='primary'>
								The ALEiGN Mantra:
							</Typography>
							<Typography
								variant='h3'
								my={2}
								sx={{ fontWeight: 700, textTransform: 'none' }}
							>
								#context #comms #culture #move2done!
							</Typography>
							<Typography variant='h5' my={2} color='primary'>
								#context
							</Typography>
							<Typography pb={1}>
								Don't use channels or folders that create silos of information & teams.
								Build tiered Domains & SubDomains that grow & evolve.
							</Typography>
							<Typography pb={1}>
								Make tasks, chats, & the dashboard contextual. Information where you
								need it, not in email or 3rd party APPs.
							</Typography>
							<Typography pb={1}>
								Macro to Micro. The high-level overview or the ground-level detail, &
								everything in between.
							</Typography>
							<Typography pb={1}>
								Subdomains & Tasks can be moved anywhere, based on participant's access.
							</Typography>
							<Typography variant='h5' my={2} color='primary'>
								#comms{' '}
							</Typography>
							<Typography variant='body1' my={2}>
								Good communication builds relationships.
							</Typography>
							<Typography pb={1}>
								Contextual chat throughout your environment puts conversations &
								important info right where it should be, not lost in 3rd party comms
								tools or email.
							</Typography>
							<Typography pb={1}>No domain or task is an island.</Typography>
							<Typography variant='h5' my={2} color='primary'>
								#culture
							</Typography>
							{/* <Typography variant='body1' my={2}>
								"Working hard for something we don’t care about is called stress;
								working hard for something we love is called passion." - Simon Sinek
							</Typography> */}
							<Typography pb={1}>
								Passionate culture starts with #context & #comms. Conversations with the
								right people at the right time about the right things.
							</Typography>
							<Typography pb={1}>
								Adding a survey, completed each time a task is #moved2done!, to
								understand how team members are feeling about their assigned work, adds
								to this. <br />
								Our survey asks these 3 questions: <br />
								DOES THIS TASK CONTRIBUTE TO THE SUBDOMAINS GOALS? <br />
								ARE WE GOING TO BE SUCCESSFUL? <br />
								DID YOU ENJOY DOING THIS TASK?
							</Typography>
							<Typography pb={1}>
								Data is mapped to a 'sentiment' graph. This graph is contextual giving
								managers a view of 'how we're all going’ & an new conversation
								opportunity.
							</Typography>
							<Typography variant='h5' my={2} color='primary'>
								#move2done!
							</Typography>
							<Typography variant='body1' my={2}>
								Ideas are S%#t. Execution is everything!
							</Typography>
							<Typography pb={1}>Keep tasks small & #move2done! often.</Typography>
							<Typography pb={1}>
								{' '}
								Keep focus on the North Star. The track winds, the destination set.
							</Typography>
							<Typography pb={1}>
								{' '}
								Try. Experiment. Iterate. Fail. Try again. Keep moving. Change the
								world.
							</Typography>
							{/* <Stack
								direction='row'
								// justifyContent='center'
								alignItems='center'
								spacing={6}
								mt={4}
							>
								<Button
									size='lg'
									href='#'
									target='_blank'
									rel='noopener noreferrer'
									variant='aleign'
								>
									I get it, <br />
									show me the detail
								</Button>
								<Button
									size='lg'
									href=' '
									target='_blank'
									rel='noopener noreferrer'
									variant='aleign'
								>
									Interesting, <br />
									Tell me more
								</Button>
							</Stack> */}
						</Grid>
					</Grid>
				</Box>
			</FrontContent>
		</Section>
	);
}

export default AboutSection;

import * as React from 'react';
import { styled } from '@mui/system';
import { alpha } from '@mui/material';

import { StaticImage } from 'gatsby-plugin-image';

// @material-ui/core components
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import Stack from '@mui/material/Stack';
import { Grid } from '@mui/material';
import { Button } from '@mui/material';

import moshedKat from '../../img/MoshedKatLogo.gif';

// import separator from '../images/separatorWhite.png';

const SectionContent = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	zIndex: 1,
	position: 'relative',
	height: '100%',
	overflow: 'hidden',
	scrollSnapType: 'y mandatory',
	scrollBehavior: 'smooth',
});

const ScrollAnchor = styled(Box)({
	position: 'absolute',
	top: '-84px',
	scrollSnapAlign: 'start',
	scrollSnapStop: 'always',
});

const JonesieSection = () => {
	return (
		<SectionContent py={0} px={6} m={0}>
			<ScrollAnchor id='contextSection' sx={{ top: -70 }} />
			<Box
				sx={{
					maxHeight: '400px',
					position: 'absolute',
					width: '100%',
					height: '100%',
					top: '0px',
					left: '0px',
					opacity: '0.2',
					img: {
						// height: '100%',
						margin: 0,
						maxWidth: 'none',
						padding: 0,
						position: 'absolute',
						right: 0,
						top: '-80px',
						// width: '100%',
						objectFit: 'cover',
					},
				}}
			>
				<img src={moshedKat} alt='ALEIGN Instructions' />
			</Box>
			<Grid container justify='space-between' sx={{ zIndex: '1' }}>
				{/* <Grid item xs={0} md={7}></Grid> */}
				<Grid item xs={12} sm={12} p={8} sx={{ textAlign: 'center' }}>
					<Typography
						variant='h3'
						mb={2}
						sx={{ fontWeight: 700, textTransform: 'none' }}
						color='#fff'
					>
						Let's talk solutions{' '}
					</Typography>
					<Typography variant='h3' color='primary' sx={{ fontWeight: 'inherit' }}>
						If all else fails,
					</Typography>
					<Typography variant='h2' color='primary' sx={{ fontWeight: 'inherit' }}>
						Follow the instructions!
					</Typography>
				</Grid>
			</Grid>
		</SectionContent>
	);
};

export default JonesieSection;
